'use client'

import { useContext, useEffect, useState } from 'react'
import Modal from '@/components/Modal'
import Wallet from '@/contexts/WalletContext'
import { ButtonColor, ButtonVariant, Hub } from '@/lib/types'
import Login from './Login'
import ReleaseCreateForm from './ReleaseCreateForm'

export default function ReleaseCreate({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [headerSteps, setHeaderSteps] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [accountHubs, setAccountHubs] = useState<Hub[]>([])
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const { wallet } = useContext(Wallet.Context)
  useEffect(() => {
    if (!isOpen) {
      setHasUnsavedChanges(false)
    }
  }, [isOpen])

  return (
    <Modal
      label={'Release'}
      hideTrigger={true}
      modalLabelVariant={ButtonVariant.Large}
      modalLabelTextColor={ButtonColor.Black}
      isOpen={isOpen}
      setIsOpen={() => {
        setIsOpen(false)
        setCurrentStep(0)
      }}
      modalHeader={
        <div className="flex flex-row">
          <p>Publish Release </p>
          {headerSteps > 0 && (
            <p className="ui-1 ml-16 text-grey-45">
              step {currentStep + 1} of {headerSteps}
            </p>
          )}
        </div>
      }
      disableKeydownSubmit={currentStep < headerSteps - 1}
      hasUnsavedChanges={hasUnsavedChanges}
    >
      <div>
        {!wallet.publicKey && (
          <Login
            loginModalLabelPromptString={
              'Create an account or sign in to publish a release.'
            }
            variant="loginPrompt"
          />
        )}
        {wallet.publicKey && (
          <ReleaseCreateForm
            setHeaderSteps={setHeaderSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            accountHubs={accountHubs}
            setAccountHubs={setAccountHubs}
            closeModal={() => {
              setIsOpen(false)
            }}
            isParentOpen={isOpen}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        )}
      </div>
    </Modal>
  )
}
